import React, { useState, useRef ,useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import Select, { components, InputAction } from 'react-select';
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Breadcrumb,
  Form
} from 'react-bootstrap';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);



const MultiSelect = (props) => {
  const [selectInput, setSelectInput] = useState("");
  const isAllSelected = useRef(false);
  const selectAllLabel = useRef("Select all");
  const allOption = { value: "*", label: selectAllLabel.current };

  const filterOptions = (options, input) =>
    options?.filter(({ label }) =>
      label.toLowerCase().includes(input.toLowerCase())
    );

  const comparator = (v1, v2) =>
    (v1.value) - (v2.value);

  let filteredOptions = filterOptions(props.options, selectInput);
  let filteredSelectedOptions = filterOptions(props.value, selectInput);

  const Option = (props) => (
    <components.Option {...props}>
      {props.value === "*" &&
      !isAllSelected.current &&
      filteredSelectedOptions?.length > 0 ? (
        <input
          key={props.value}
          type="checkbox"
          ref={(input) => {
            if (input) input.indeterminate = true;
          }}
        />
      ) : (
        <input
          key={props.value}
          type="checkbox"
          checked={props.isSelected || isAllSelected.current}
          onChange={() => {}}
        />
      )}
      <label style={{ marginLeft: "5px" }}>{props.label}</label>
    </components.Option>
  );

  const Input = (props) => (
    <>
      {selectInput.length === 0 ? (
        <components.Input autoFocus={props.selectProps.menuIsOpen} {...props}>
          {props.children}
        </components.Input>
      ) : (
        <div style={{ border: "1px dotted gray" }}>
          <components.Input autoFocus={props.selectProps.menuIsOpen} {...props}>
            {props.children}
          </components.Input>
        </div>
      )}
    </>
  );

  const customFilterOption = ({ value, label }, input) =>
    (value !== "*" && label.toLowerCase().includes(input.toLowerCase())) ||
    (value === "*" && filteredOptions?.length > 0);

  const onInputChange = (inputValue, event) => {
    if (event.action === "input-change") setSelectInput(inputValue);
    else if (event.action === "menu-close" && selectInput !== "")
      setSelectInput("");
  };

  const onKeyDown = (e) => {
    if ((e.key === " " || e.key === "Enter") && !selectInput)
      e.preventDefault();
  };

  const handleChange = (selected) => {
    if (
      selected.length > 0 &&
      !isAllSelected.current &&
      (selected[selected.length - 1].value === allOption.value ||
        JSON.stringify(filteredOptions) ===
          JSON.stringify(selected.sort(comparator)))
    )
      return props.onChange(
        [
          ...(props.value ?? []),
          ...props.options.filter(
            ({ label }) =>
              label.toLowerCase().includes(selectInput?.toLowerCase()) &&
              (props.value ?? []).filter((opt) => opt.label === label)
                .length === 0
          ),
        ].sort(comparator)
      );
    else if (
      selected.length > 0 &&
      selected[selected.length - 1].value !== allOption.value &&
      JSON.stringify(selected.sort(comparator)) !==
        JSON.stringify(filteredOptions)
    )
      return props.onChange(selected);
    else
      return props.onChange([
        ...props.value?.filter(
          ({ label }) =>
            !label.toLowerCase().includes(selectInput?.toLowerCase())
        ),
      ]);
  };

  const customStyles = {
    multiValueLabel: (def) => ({
      ...def,
      backgroundColor: "lightgray",
    }),
    multiValueRemove: (def) => ({
      ...def,
      backgroundColor: "lightgray",
    }),
    valueContainer: (base) => ({
      ...base,
      maxHeight: "65px",
      overflow: "auto",
    }),
    option: (styles, { isSelected, isFocused }) => {
      return {
        ...styles,
        backgroundColor:
          isSelected && !isFocused
            ? null
            : isFocused && !isSelected
            ? styles.backgroundColor
            : isFocused && isSelected
            ? "#DEEBFF"
            : null,
        color: isSelected ? null : null,
      };
    },
    menu: (def) => ({ ...def, zIndex: 9999 }),
  };

  if (props.isSelectAll && props.options.length !== 0) {
    isAllSelected.current =
      JSON.stringify(filteredSelectedOptions) ===
      JSON.stringify(filteredOptions);

    if (filteredSelectedOptions?.length > 0) {
      if (filteredSelectedOptions?.length === filteredOptions?.length)
        selectAllLabel.current = `All (${filteredOptions.length}) selected`;
      else
        selectAllLabel.current = `${filteredSelectedOptions?.length} / ${filteredOptions.length} selected`;
    } else selectAllLabel.current = "Select all";

    allOption.label = selectAllLabel.current;

    return (
      <Select
        {...props}
        inputValue={selectInput}
        onInputChange={onInputChange}
        onKeyDown={onKeyDown}
        options={[allOption, ...props.options]}
        onChange={handleChange}
        components={{
          Option: Option,
          Input: Input,
          ...props.components,
        }}
        filterOption={customFilterOption}
        menuPlacement={props.menuPlacement ?? "auto"}
        styles={customStyles}
        isMulti
        closeMenuOnSelect={false}
        tabSelectsValue={false}
        backspaceRemovesValue={false}
        hideSelectedOptions={false}
        blurInputOnSelect={false}
      />
    );
  }

  return (
    <Select
      {...props}
      inputValue={selectInput}
      onInputChange={onInputChange}
      filterOption={customFilterOption}
      components={{
        Input: Input,
        ...props.components,
      }}
      menuPlacement={props.menuPlacement ?? "auto"}
      onKeyDown={onKeyDown}
      tabSelectsValue={false}
      hideSelectedOptions={true}
      backspaceRemovesValue={false}
      blurInputOnSelect={true}
    />
  );
};

const DealerAction = () => {
  const { id } = useParams();
  const [dealerID, setDealerID] = useState(id);
  const [dealerDetails, setDealerDetails] = useState([{ make: '', vehicleTypes: [] }]);
  const [carData, setCarData] = useState({});

  // Fetch car data from API on component mount
  useEffect(() => {
    fetch('https://api.icarbuyer.co/api/make/make-summary')
      .then(response => response.json())
      .then(data => {
        // Transform API response into the required format
        const transformedCarData = {};
        data.forEach(make => {
          const makeName = make.name.toUpperCase(); // Ensure consistency in make names
          const vehicleTypes = make.vehicleTypesDeails.map(detail => detail.vehicleTypeName.name.toUpperCase());
          transformedCarData[makeName] = vehicleTypes;
        });
        setCarData(transformedCarData);
      })
      .catch(error => {
        console.error('Error fetching car data:', error);
      });
  }, []); // Empty dependency array ensures the effect runs only once on mount

  const handleMakeChange = (selectedMake, index) => {
    const newDealerDetails = [...dealerDetails];
    newDealerDetails[index].make = selectedMake.value;
    newDealerDetails[index].vehicleTypes = [];
    setDealerDetails(newDealerDetails);
  };

  const handleVehicleTypeChange = (selectedVehicleTypes, index) => {
    const newDealerDetails = [...dealerDetails];
    newDealerDetails[index].vehicleTypes = selectedVehicleTypes.map(vehicleType => vehicleType.value);
    setDealerDetails(newDealerDetails);
  };

  const addMoreDealerDetails = () => {
    setDealerDetails([...dealerDetails, { make: '', vehicleTypes: [] }]);
  };

  const removeDealerDetails = (index) => {
    const newDealerDetails = [...dealerDetails];
    newDealerDetails.splice(index, 1);
    setDealerDetails(newDealerDetails.length ? newDealerDetails : [{ make: '', vehicleTypes: [] }]);
  };

  const saveDetails = () => {
    fetch(`https://api.icarbuyer.co/api/dealer/dealers/addMakes/${dealerID}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ makes: dealerDetails })
    })
    .then(response => response.json())
    .then(data => {
      MySwal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Dealer details have been saved successfully!'
      });
      console.log('Success:', data);
    })
    .catch(error => {
      MySwal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to save dealer details. Please try again.'
      });
      console.error('Error:', error);
    });
  };

  const selectedMakes = dealerDetails.map(detail => detail.make);
  const availableMakeOptions = Object.keys(carData).map(make => ({
    value: make,
    label: make
  })).filter(make => !selectedMakes.includes(make.value));


  return (
    <div className="main-content">
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs={12}>
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="text-info m-1">ADD DEALER DETAILS</h4>
                <div className="page-title-right">
                  <Breadcrumb>
                    <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                    <Breadcrumb.Item active className="text-info">Dealer Actions</Breadcrumb.Item>
                  </Breadcrumb>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl={12}>
              <Card className="custom-shadow rounded-lg border my-2">
                <Card.Body>
                  {dealerDetails.map((detail, index) => (
                    <div key={index} className="mb-3">
                      <Row className="align-items-center">
                        <Col md={4}>
                          <Form.Group>
                            <Form.Label>Make</Form.Label>
                            <Select
                              value={detail.make ? { value: detail.make, label: detail.make } : null}
                              onChange={(selectedMake) => handleMakeChange(selectedMake, index)}
                              options={availableMakeOptions}
                              classNamePrefix="select"
                              placeholder="Select Make"
                            />
                          </Form.Group>
                        </Col>
                        <Col md={4} >
                          <Form.Group>
                            <Form.Label>Vehicle Types</Form.Label>
                            <MultiSelect
                              value={detail.vehicleTypes.map(type => ({ value: type, label: type }))}
                              onChange={(selectedVehicleTypes) => handleVehicleTypeChange(selectedVehicleTypes, index)}
                              options={detail.make ? carData[detail.make].map(vehicleType => ({
                                value: vehicleType,
                                label: vehicleType
                              })) : []}
                              isMulti
                              classNamePrefix="select"
                              placeholder="Select Vehicle Types"
                              isSelectAll={true}
                              menuPlacement={"bottom"}
                            />
                          </Form.Group>
                        </Col>
                        <Col md={4} className="d-flex align-items-end">
                          <div className="d-flex justify-content-between w-100 mt-4">
                            {dealerDetails.length > 1 && (
                              <Button variant="danger" onClick={() => removeDealerDetails(index)} className="mr-2 ">
                                Remove
                              </Button>
                            )}
                            {index === dealerDetails.length - 1 && (
                              <Button variant="primary" onClick={addMoreDealerDetails}>
                                Add More
                              </Button>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  ))}
                  <div className="d-flex justify-content-end mt-3 ">
                    <Button variant="success" onClick={saveDetails} className='mx-2'>
                      Save
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default DealerAction;
