import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Form, Button, Row, Col } from 'react-bootstrap';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Constants } from 'src/Constants';  // Make sure your constants file has the correct endpoints

const MySwal = withReactContent(Swal);

const AddNewPlan = () => {
  const navigate = useNavigate();
  const { id } = useParams();             // Capture plan ID from route, if present
  const isEditMode = Boolean(id);         // If there is an 'id', we're editing

  // Initialize form data state
  const [formData, setFormData] = useState({
    planName: '',
    leads: '',
    price: '',
    additionalLeadPrice: '',
  });

  // Initialize errors state
  const [errors, setErrors] = useState({});

  // ---------------------------
  // FETCH THE PLAN IF EDITING
  // ---------------------------
  useEffect(() => {
    if (isEditMode) {
      // e.g. GET: https://api.icarbuyer.co/api/plans/:id
      const fetchPlanById = async () => {
        try {
          const response = await fetch(`${Constants.getPlanById}${id}`, {
            method: 'GET',
          });
          const result = await response.json();

          if (!response.ok || result.status !== 1) {
            throw new Error(result.message || 'Failed to fetch plan information.');
          }

          const { info } = result; 
          // Populate the form with returned data
          setFormData({
            planName: info.planName || '',
            leads: info.leads || '',
            price: info.price || '',
            additionalLeadPrice: info.additionalLeadPrice || '',
          });
        } catch (error) {
          console.error('Error fetching plan:', error.message);
          MySwal.fire('Error', error.message, 'error');
        }
      };
      fetchPlanById();
    }
  }, [isEditMode, id]);

  // Handle input changes
  const handleChange = (e) => {
    const { name, value, type } = e.target;
    // For number fields, convert value to number or keep as empty string
    const processedValue =
      type === 'number' ? (value !== '' ? Number(value) : '') : value;

    setFormData({
      ...formData,
      [name]: processedValue,
    });
  };

  // Validate form fields
  const validateForm = () => {
    const newErrors = {};

    // Validate Plan Name
    if (!formData.planName.trim()) {
      newErrors.planName = 'Plan Name is required';
    }

    // Validate Price Per Month
    if (formData.price === '') {
      newErrors.price = 'Price Per Month is required';
    } else if (isNaN(formData.price) || formData.price <= 0) {
      newErrors.price = 'Price Per Month must be a valid positive number';
    }

    // Validate Leads Per Month
    if (formData.leads === '') {
      newErrors.leads = 'Leads Per Month is required';
    } else if (isNaN(formData.leads) || formData.leads <= 0) {
      newErrors.leads = 'Leads Per Month must be a valid positive number';
    }

    // Validate Additional Leads Charge
    if (formData.additionalLeadPrice === '') {
      newErrors.additionalLeadPrice = 'Additional Leads Charge is required';
    } else if (
      isNaN(formData.additionalLeadPrice) ||
      formData.additionalLeadPrice <= 0
    ) {
      newErrors.additionalLeadPrice =
        'Additional Leads Charge must be a valid positive number';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Handle form submission (Add or Edit)
  const handleSave = async () => {
    if (!validateForm()) {
      MySwal.fire(
        'Error',
        'Please fill out all required fields with valid data.',
        'error'
      );
      return;
    }

    try {
      if (isEditMode) {
        // ---------------
        // EDIT SCENARIO
        // ---------------
        // PUT: https://api.icarbuyer.co/api/plans/:id
        const response = await fetch(`${Constants.updatePlanById}${id}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            planName: formData.planName,
            leads: formData.leads,
            price: formData.price,
            additionalLeadPrice: formData.additionalLeadPrice,
          }),
        });

        const result = await response.json();

        if (!response.ok || result.status !== 1) {
          throw new Error(result.message || 'Failed to update plan information.');
        }

        MySwal.fire('Updated!', result.message || 'Plan information has been updated.', 'success');
        navigate('/planList');
      } else {
        // -------------
        // ADD SCENARIO
        // -------------
        // POST: https://api.icarbuyer.co/api/plans
        const response = await fetch(Constants.addNewPlan, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            planName: formData.planName,
            leads: formData.leads,
            price: formData.price,
            additionalLeadPrice: formData.additionalLeadPrice,
          }),
        });

        const result = await response.json();

        if (!response.ok || result.status !== 1) {
          throw new Error(result.message || 'Failed to save plan information.');
        }

        MySwal.fire('Saved!', result.message || 'Plan information has been saved.', 'success');
        navigate('/planList');
      }
    } catch (error) {
      console.error('Error saving data:', error.message);
      MySwal.fire('Error', error.message || 'Failed to save plan information.', 'error');
    }
  };

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">

          {/* Page Title */}
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0 text-info">
                  {isEditMode ? 'EDIT SUBSCRIPTION PLAN' : 'ADD NEW PLAN'}
                </h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item active text-info">
                      {isEditMode ? 'Edit Subscription Plan' : 'Add New Subscription Plan'}
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          {/* Form Card */}
          <div className="row">
            <div className="col-xl-12">
              <div className="card custom-shadow rounded-lg border my-2">
                <div className="card-body">
                  <h5 className="h6 text-uppercase mb-3">
                    Subscription Plan Information
                  </h5>
                  <Form>
                    <Row>
                      {/* Plan Name */}
                      <Col lg={6} className="mb-3">
                        <Form.Group controlId="planName">
                          <Form.Label>Plan Name</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter title of plan"
                            name="planName"
                            value={formData.planName}
                            onChange={handleChange}
                            isInvalid={!!errors.planName}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.planName}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>

                      {/* Price Per Month */}
                      <Col lg={6} className="mb-3">
                        <Form.Group controlId="price">
                          <Form.Label>Price Per Month</Form.Label>
                          <Form.Control
                            type="number"
                            placeholder="Enter price"
                            name="price"
                            value={formData.price}
                            onChange={handleChange}
                            isInvalid={!!errors.price}
                            min="0"
                            step="0.01"
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.price}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>

                      {/* Leads Per Month */}
                      <Col lg={6} className="mb-3">
                        <Form.Group controlId="leads">
                          <Form.Label>Leads Per Month</Form.Label>
                          <Form.Control
                            type="number"
                            placeholder="Enter total leads"
                            name="leads"
                            value={formData.leads}
                            onChange={handleChange}
                            isInvalid={!!errors.leads}
                            min="0"
                            step="1"
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.leads}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>

                      {/* Additional Leads Charge */}
                      <Col lg={6} className="mb-3">
                        <Form.Group controlId="additionalLeadPrice">
                          <Form.Label>Additional Leads Charge</Form.Label>
                          <Form.Control
                            type="number"
                            placeholder="Enter additional lead price"
                            name="additionalLeadPrice"
                            value={formData.additionalLeadPrice}
                            onChange={handleChange}
                            isInvalid={!!errors.additionalLeadPrice}
                            min="0"
                            step="0.01"
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.additionalLeadPrice}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>

                    {/* Action Buttons */}
                    <div className="text-end">
                      <Button
                        variant="outline-info"
                        className="min-btn m-2"
                        onClick={() => navigate('/planList')}
                      >
                        Back
                      </Button>
                      <Button variant="info" className="min-btn m-2" onClick={handleSave}>
                        {isEditMode ? 'Update' : 'Save'}
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
          {/* End Form Card */}
        </div>
      </div>
    </div>
  );
};

export default AddNewPlan;
