import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button } from 'react-bootstrap'
import { ImCross } from 'react-icons/im'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import { Constants } from 'src/Constants'

const MySwal = withReactContent(Swal)

const AddNewVehicleType = () => {
  const [makeName, setMakeName] = useState('')
  const [status, setStatus] = useState('active')

  const handleSave = async () => {
    try {
      const requestData = {
        name: makeName,
        status: status,
      }

      const response = await fetch(Constants.addNewVehicleType, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
      })

      if (response.ok) {
        MySwal.fire('Saved!', 'Vehicle Type information has been saved.', 'success')
        setMakeName('')
        setStatus('active')
      } else {
        throw new Error('Failed to save Vehicle Type information.')
      }
    } catch (error) {
      console.error('Error:', error)
      MySwal.fire('Error', 'Failed to save Vehicle Type information.', 'error')
    }
  }

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0 text-info">VEHICLE TYPE LISTINGS</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item active text-info">Add New Vehicle Type</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-12">
              <div className="card custom-shadow rounded-lg border my-3">
                <div className="card-body">
                  <h5 className="h6 text-uppercase mb-3">Vehicle Type Information</h5>
                  <Form className="row">
                    <Form.Group className="mb-3" controlId="makeName">
                      <Form.Label>Vehicle Type Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Vehicle Type Name"
                        value={makeName}
                        onChange={(e) => setMakeName(e.target.value)}
                        onBlur={(e) => setMakeName(e.target.value.replace(/\s/g, ''))}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="status">
                      <Form.Label>Status</Form.Label>
                      <Form.Control
                        as="select"
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                      >
                        <option value="active">Active</option>
                        <option value="inactive">Inactive</option>
                      </Form.Control>
                    </Form.Group>

                    <div className="text-end">
                      {/* <Button
                        variant="outline-info"
                        className="min-btn m-2"
                        onClick={() => MySwal.fire('Cancelled', 'Operation cancelled', 'info')}
                      >
                        Cancel
                      </Button> */}
                      <Button variant="info" className="min-btn m-2" onClick={handleSave}>
                        Save
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddNewVehicleType
