import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Form, Button } from 'react-bootstrap'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Constants } from 'src/Constants'

const MySwal = withReactContent(Swal)

const AddNewCustomer = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
   

    address: '',
    suburb: '',
    postCodes: '',
    category:'',
    division:'',
    businessName: '', 
    province:'',
    website: '',
    twitter:'',
    youtube:'',
    facebook:'',
    fax:'',
    tollFree:'',
   
    phone: '',
    email: '',
    password: '',
  
    status: 'active',
  });

 
  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const handleSave = async () => {
    try {
      const response = await fetch(Constants.addNewDealer, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      })

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`)
      }

      MySwal.fire('Saved!', 'Dealer information has been saved.', 'success')
      navigate('/customerList'); 
    } catch (error) {
      console.error('Error saving data:', error.message)
      MySwal.fire('Error', 'Failed to save dealer information.', 'error')
    }
  }

  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0 text-info">ADD NEW DEALER</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li className="breadcrumb-item active text-info">Add New Dealer</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-12">
                <div className="card custom-shadow rounded-lg border my-2">
                  <div className="card-body">
                    <h5 className="h6 text-uppercase mb-3">Dealer Registration </h5>
                    <Form>
                      <div className="row">
                      <Form.Group className="col-lg-6 mb-3" controlId="businessName">
                          <Form.Label>Name of Dealership</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Name of Dealership"
                            name="businessName"
                            value={formData.businessName}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group className="col-lg-6 mb-3" controlId="phone">
                          <Form.Label>Business Telephone Number</Form.Label>
                          <Form.Control
                            type="number"
                            placeholder="Enter Business Telephone Number"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group className="col-lg-6 mb-3" controlId="category">
                          <Form.Label>Category</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter category"
                            name="category"
                            value={formData.category}
                            onChange={handleChange}
                          />
                        </Form.Group> <Form.Group className="col-lg-6 mb-3" controlId="division">
                          <Form.Label>Division</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Division"
                            name="division"
                            value={formData.division}
                            onChange={handleChange}
                          />
                        </Form.Group>
                       

                        <Form.Group className="col-lg-6  mb-3" controlId="address">
                          <Form.Label>Street Address</Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={1}
                            placeholder="Enter Street Address"
                            name="address"
                            value={formData.address}
                            onChange={handleChange}
                          />
                        </Form.Group>

                        <Form.Group className="col-lg-6 mb-3" controlId="suburb">
                          <Form.Label>Suburb</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Suburb"
                            name="suburb"
                            value={formData.suburb}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group className="col-lg-6 mb-3" controlId="province">
                          <Form.Label>Province</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Province"
                            name="province"
                            value={formData.province}
                            onChange={handleChange}
                          />
                        </Form.Group>

                        <Form.Group className="col-lg-6 mb-3" controlId="postCodes">
                          <Form.Label>Postal Code</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Postal Code"
                            name="postCodes"
                            value={formData.postCodes}
                            onChange={handleChange}
                          />
                        </Form.Group>
                       
                        
                        <Form.Group className="col-lg-6  mb-3" controlId="website">
                          <Form.Label>Website</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Website"
                            name="website"
                            value={formData.website}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group className="col-lg-6  mb-3" controlId="twitter">
                          <Form.Label>Twitter</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Twitter"
                            name="twitter"
                            value={formData.twitter}
                            onChange={handleChange}
                          />
                        </Form.Group>

                        <Form.Group className="col-lg-6  mb-3" controlId="youtube">
                          <Form.Label>Youtube</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Youtube"
                            name="youtube"
                            value={formData.youtube}
                            onChange={handleChange}
                          />
                        </Form.Group>

                        <Form.Group className="col-lg-6  mb-3" controlId="facebook">
                          <Form.Label>Facebook</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Facebook"
                            name="facebook"
                            value={formData.facebook}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group className="col-lg-6  mb-3" controlId="otherSocial">
                          <Form.Label>Other Social</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Other Social"
                            name="otherSocial"
                            value={formData.otherSocial}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group className="col-lg-6  mb-3" controlId="fax">
                          <Form.Label>Fax</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Fax"
                            name="fax"
                            value={formData.fax}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group className="col-lg-6  mb-3" controlId="tollFree">
                          <Form.Label>TollFree</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter TollFree"
                            name="tollFree"
                            value={formData.tollFree}
                            onChange={handleChange}
                          />
                        </Form.Group>



                     

                       

                       
                        

                        <Form.Group className="mb-3" controlId="email">
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            type="email"
                            placeholder="Enter Email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                          />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="password">
                          <Form.Label>Password</Form.Label>
                          <Form.Control
                            type="password"
                            placeholder="Enter Password"
                            name="password"
                            value={formData.password}
                            onChange={handleChange}
                          />
                        </Form.Group>

                        <div className="text-end">
                          <Button
                            variant="outline-info"
                            className="min-btn m-2"
                            onClick={() => MySwal.fire('Cancelled', 'Operation cancelled', 'info')}
                          >
                            Cancel
                          </Button>
                          <Button variant="info" className="min-btn m-2" onClick={handleSave}>
                            Save
                          </Button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AddNewCustomer
