import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Form, Button } from 'react-bootstrap'
import { ImCross } from 'react-icons/im'
import Swal from 'sweetalert2'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import withReactContent from 'sweetalert2-react-content'
import { SketchPicker } from 'react-color'
import { Constants } from 'src/Constants'

const MySwal = withReactContent(Swal)
const EditMakeDetails = () => {
  const {id} = useParams()
  const navigate = useNavigate()
  // const id = '6603c030426564a41257535a'
  const [makeName, setMakeName] = useState('')
  const [status, setStatus] = useState('active')
  const [primaryColor, setPrimaryColor] = useState('')
  const [secondaryColor, setSecondaryColor] = useState('')
  const [bannerImage, setBannerImage] = useState(null)
  const [bannerImageName,setBannerImageName] = useState('')

  const [heading, setHeading] = useState('')
  const [description, setDescription] = useState('')
  const [brandIcon, setBrandIcon] = useState(null)
  const [brandIconImage,setBrandIconName] = useState('')

  const [image, setImage] = useState(null)
  const [imageName, setImageName] = useState('');
  const [vehicleTypes, setVehicleTypes] = useState([])
  const [selectedVehicleTypes, setSelectedVehicleTypes] = useState([])
  const [vehicleTypesDetails, setVehicleTypesDetails] = useState([])
  const [checkedVehicleTypes, setCheckedVehicleTypes] = useState([])
  const [makeData, setMakeData] = useState(null)

  useEffect(() => {
    const fetchMakesAndVehicleTypes = async () => {
      try {
        const vehicleTypesResponse = await fetch('https://api.icarbuyer.co/api/vehicle/vehicleType')
        if (!vehicleTypesResponse.ok) {
          throw new Error(`HTTP error! Status: ${vehicleTypesResponse.status}`)
        }
        const vehicleTypesData = await vehicleTypesResponse.json()

        const activeVehicleTypes = vehicleTypesData.filter((type) => type.status === 'active')
        const vehicleTypeNames = activeVehicleTypes.map((type) => {
          return { name: type.name, id: type._id }
        })
        setVehicleTypes(vehicleTypeNames)
      } catch (error) {
        console.error('Error fetching data:', error.message)
      }
    }

    fetchMakesAndVehicleTypes()
  }, [])
  useEffect(() => {
    fetch(`https://api.icarbuyer.co/api/make/make/${id}`)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setMakeData(data)
          setMakeName(data.name)
          setPrimaryColor('#c32323')
          setSecondaryColor('#c32323')
          setHeading(data.heading)
          setDescription(data.description)
          setBrandIcon(data.brandIcon)
          setBrandIconName(data.brandIcon)
          setBannerImage(data.bannerImage)
          setBannerImageName(data.bannerImage)
          setImage(data.image)
          setImageName(data.image)
          
          setSelectedVehicleTypes(data.vehicleTypesDeails.map((item) => item.vehicleTypeName.name))
          setVehicleTypesDetails(
            data.vehicleTypesDeails.map((item) => {
              return {
                vehicleTypeName: item.vehicleTypeName._id,
                vehicleTypeHeading: item.vehicleTypeHeading,
                backgroundImg: item.backgroundImg._id ?? '',
                iconImg: item.iconImg._id ?? '',
                bannerImg: item.bannerImg ? item.bannerImg._id : '',
              }
            }),
          )
        }
      })
      .catch((error) => console.error('Error fetching make details:', error))
  }, [id])

  const handleVehicleTypeCheckboxChange = (typeName) => {
    console.log(typeName)
    if (selectedVehicleTypes.includes(typeName.name)) {
      setSelectedVehicleTypes(
        selectedVehicleTypes.filter((selectedType) => selectedType !== typeName.name),
      )
    } else {
      setVehicleTypesDetails([
        ...vehicleTypesDetails,
        {
          vehicleTypeName: typeName.id,
          vehicleTypeHeading: '',
          backgroundImg: '',
          iconImg: '',
          bannerImg: '',
        },
      ])
      setSelectedVehicleTypes([...selectedVehicleTypes, typeName.name])
    }
    if (selectedVehicleTypes.includes(typeName.name)) {
      setSelectedVehicleTypes(
        selectedVehicleTypes.filter((selectedType) => selectedType !== typeName.name),
      )
    } else {
      setSelectedVehicleTypes([...selectedVehicleTypes, typeName.name])
    }
  }

  const handleVehicleTypeDetailsChange = (typeName, field, value) => {
    const temp = [...vehicleTypesDetails]
    const foundItem = temp.find((item) => item.vehicleTypeName === typeName.id)
    foundItem[field] = value
    setVehicleTypesDetails(temp)
  }



  // console.log(makeData)
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
      setImageName(file.name);
    }
  };
  const handleBrandIconChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setBrandIcon(file);
      setBrandIconName(file.name); 
    }
  };
  const handleBannerImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setBannerImage(file);
      setBannerImageName(file.name); 
    }
  };
  const handleVehicleTypeImageChange = async (typeName, field, file) => {
    try {
      const formData = new FormData()
      formData.append('file', file)

      const response = await fetch('https://api.icarbuyer.co/api/files/upload', {
        method: 'POST',
        body: formData,
      })

      if (!response.ok) {
        throw new Error('Failed to upload image')
      }

      const data = await response.json()
      const temp = [...vehicleTypesDetails]
      const foundItem = temp.find((item) => item.vehicleTypeName === typeName.id)
      foundItem[field] = data.file._id
      setVehicleTypesDetails(temp)
    } catch (error) {
      console.error('Error uploading image:', error)
    }
  }
  const handleSave = async () => {
    try {
    
      const formData = new FormData()
      formData.append('name', makeName)
      formData.append('primaryColor', primaryColor)
      formData.append('secondaryColor', secondaryColor)
      formData.append('status', status)
      formData.append('heading', heading)
      formData.append('description', description)
      if (bannerImage) {
          formData.append('bannerImage', bannerImage)
        
      }
      if (brandIcon) {
        formData.append('brandIcon', brandIcon)
      }

      if (image) {
        formData.append('image', image)
      }

      selectedVehicleTypes.forEach((type) => {
        formData.append('vehicleType', type)
      })
      formData.append('vehicleTypesDeails', JSON.stringify(vehicleTypesDetails))

      const response = await fetch(`https://api.icarbuyer.co/api/make/make/${id}`, {
        method: 'PUT',
        body: formData,
      })

      if (response.ok) {
        MySwal.fire('Saved!', 'Make information has been saved.', 'success')
        navigate('/productList')

     
      } else {
        throw new Error('Failed to save make information.')
      }
    } catch (error) {
      console.error('Error:', error)
      MySwal.fire('Error', 'Failed to save make information.', 'error')
    }
  } 
  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0 text-info">EDIT MAKE / BRAND</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item active text-info">Edit Make</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-12">
              <div className="card custom-shadow rounded-lg border my-3">
                <div className="card-body">
                  <h5 className="h6 text-uppercase mb-3">Make Information</h5>
                  <Form className="row" >
                    <Form.Group className="mb-3" controlId="makeName">
                      <Form.Label>Make Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Make Name"
                        value={makeName}
                        onChange={(e) => setMakeName(e.target.value)}
                      />
                    </Form.Group>

                    <Form.Group className="col-lg-6 mb-3" controlId="primaryColor">
                      <Form.Label>Primary Color</Form.Label>
                      <SketchPicker
                        color={primaryColor}
                        onChange={(color) => setPrimaryColor(color.hex)}
                      />
                    </Form.Group>

                    <Form.Group className="col-lg-6 mb-3" controlId="secondaryColor">
                      <Form.Label>Secondary Color</Form.Label>
                      <SketchPicker
                        color={secondaryColor}
                        onChange={(color) => setSecondaryColor(color.hex)}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="heading">
                      <Form.Label>Heading</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Heading"
                        value={heading}
                        onChange={(e) => setHeading(e.target.value)}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="description">
                      <Form.Label> Description</Form.Label>
                      <ReactQuill
                        theme="snow"
                        value={description}
                        onChange={setDescription}
                        modules={{
                          toolbar: [
                            [{ header: '1' }, { header: '2' }, { font: [] }],
                            [{ size: [] }],
                            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                            [
                              { list: 'ordered' },
                              { list: 'bullet' },
                              { indent: '-1' },
                              { indent: '+1' },
                            ],
                            ['link', 'image', 'video'],
                            ['clean'],
                          ],
                        }}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="bannerImage">
                      <Form.Label>Banner Image</Form.Label>
                      
                      <Form.Control
                        type="file"
                        accept="image/*"
                        onChange={handleBannerImageChange}
                      />
                     Selected File: {bannerImageName}
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="brandIcon">
                      <Form.Label>Brand Icon</Form.Label>
                     
                      <Form.Control
                        type="file"
                        accept="image/*"
                        onChange={handleBrandIconChange}
                      />
                      Selected File: {brandIconImage}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="image">
                      <Form.Label>Background Image</Form.Label>
                      
                      <Form.Control
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange}
                      />
                      Selected Image: {imageName}
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="status">
                      <Form.Label>Status</Form.Label>
                      <Form.Control
                        as="select"
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                      >
                        <option value="active">Active</option>
                        <option value="inactive">Inactive</option>
                      </Form.Control>
                    </Form.Group>

                    {/* start */}

                    <Form.Group className="mb-3 mt-3" controlId="vehicleTypes">
                      <Form.Label>Vehicle Types (Select From The list)</Form.Label>
                      <ul className="nav nav-pills mb-3 vehith" id="pills-tab" role="tablist">
                        {vehicleTypes.map((typeName, index) => (
                          <li className="nav-item" key={index}>
                            <button
                              className={`nav-link ${index === 0 ? 'active ' : ''}`}
                              style={{
                                borderRadius: '50px',
                                color: selectedVehicleTypes.includes(typeName.name)
                                  ? '#fff'
                                  : '#3c4b64',
                                backgroundColor: selectedVehicleTypes.includes(typeName.name)
                                  ? 'green'
                                  : '#f1f1f1',
                                border: '1px solid',
                                borderColor: selectedVehicleTypes.includes(typeName.name)
                                  ? 'green'
                                  : '#f1f1f1',
                                marginRight: '10px',
                                marginBottom: '12px',
                                transition: 'all',
                              }}
                              id={`pills-${typeName.name.split(" ").join("")}-tab`}
                              data-bs-toggle="pill"
                              data-bs-target={`#pills-${typeName.name.split(" ").join("")}`}
                              type="button"
                              role="tab"
                              aria-controls={`pills-${typeName.name.split(" ").join("")}`}
                              aria-selected={index === 0 ? 'true' : 'false'}
                            >
                              {typeName.name}
                            </button>
                          </li>
                        ))}
                      </ul>
                      <div className="tab-content" id="pills-tabContent">
                        {vehicleTypes.map((typeName, index) => (
                          <div
                            className={`tab-pane fade ${index === 0 ? 'show active' : ''}`}
                            id={`pills-${typeName.name.split(" ").join("")}`}
                            role="tabpanel"
                            aria-labelledby={`pills-${typeName.name.split(" ").join("")}-tab`}
                            key={index}
                          >
                            
                            <div className="d-flex flex-wrap">
                              <Form.Check
                                type="checkbox"
                                label={typeName.name}
                                checked={selectedVehicleTypes.includes(typeName.name)}
                                onChange={() => handleVehicleTypeCheckboxChange(typeName)}
                              />
                            </div>
                            <div style={{ minHeight: '150px' }}>
                              {selectedVehicleTypes.includes(typeName.name) && (
                                <div className="">
                                  <div className="mb-3">
                                    <Form.Label>Vehicle Type Heading</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Enter Vehicle Type Heading"
                                      value={vehicleTypesDetails.find(item=>item.vehicleTypeName === typeName.id)?.vehicleTypeHeading}
                                      onChange={(e) =>
                                        handleVehicleTypeDetailsChange(
                                          typeName,
                                          'vehicleTypeHeading',
                                          e.target.value.replace(/:/g, ''), // Replace all colons with an empty string
                                        )
                                      }
                                    />
                                  </div>
                                  <div className="mb-3">
                                    <Form.Label>Background Image</Form.Label>
                                    {makeData.vehicleTypesDeails.find(item=>item.vehicleTypeName._id === typeName.id)?.backgroundImg?.file}
                                    {/* field name for image upload backgroundImg */}
                                    <Form.Control
                                      type="file"
                                      accept="image/*"
                                      onChange={(e) =>
                                        handleVehicleTypeImageChange(
                                          typeName,
                                          'backgroundImg',
                                          e.target.files[0],
                                        )
                                      }
                                    />
                                  </div>
                                  <div className="mb-3">
                                    <Form.Label>Icon Image</Form.Label>
                                    {makeData.vehicleTypesDeails.find(item=>item.vehicleTypeName._id === typeName.id)?.iconImg?.file}
                                    {/* field name for image upload iconImg */}
                                    <Form.Control
                                      type="file"
                                      accept="image/*"
                                      onChange={(e) =>
                                        handleVehicleTypeImageChange(
                                          typeName,
                                          'iconImg',
                                          e.target.files[0],
                                        )
                                      }
                                    />
                                  </div>

                                  <div className="mb-3">
                                    <Form.Label>Banner Image</Form.Label>
                                    {/* field name for image upload iconImg */}
                                    {makeData.vehicleTypesDeails.find(item=>item.vehicleTypeName._id === typeName.id)?.bannerImg?.file}
                                    <Form.Control
                                      type="file"
                                      accept="image/*"
                                      onChange={(e) =>
                                        handleVehicleTypeImageChange(
                                          typeName,
                                          'bannerImg',
                                          e.target.files[0],
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    </Form.Group>

                    <div className="text-end">
                      <Button
                        // variant="info"
                        className="min-btn m-2"
                        onClick={handleSave}
                        style={{
                          backgroundColor: '#3c4b64',
                          color: 'white',
                          transition: 'transform 0.2s ease-in-out',

                          ':hover': {
                            transform: 'scale(1.1)',
                          },
                        }}
                      >
                        Save
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditMakeDetails
