import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { Container, Row, Col, Card, Spinner, Breadcrumb, Button } from 'react-bootstrap';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

const ViewSubscriptionPlan = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [plan, setPlan] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchPlan = async () => {
      try {
        const response = await fetch(`https://api.icarbuyer.co/api/plans/${id}`);
        const result = await response.json();
        if (!response.ok || result.status !== 1) {
          throw new Error(result.message || 'Failed to fetch plan information.');
        }
        setPlan(result.info);
      } catch (err) {
        console.error('Error fetching plan:', err.message);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    fetchPlan();
  }, [id]);

  const capitalizeFirstLetter = (string) => string ? string.charAt(0).toUpperCase() + string.slice(1) : 'N/A';

  if (loading) {
    return (
      <Container className="py-5 text-center">
        <Spinner animation="border" variant="info" />
        <p>Loading subscription plan...</p>
      </Container>
    );
  }

  if (error) {
    return (
      <Container className="py-5 text-center">
        <p className="text-danger">Error: {error}</p>
        <Link to="/planList" className="btn btn-info">Back to Plan List</Link>
      </Container>
    );
  }

  if (!plan) {
    return (
      <Container className="py-5 text-center">
        <p className="text-warning">No plan data found.</p>
        <Link to="/planList" className="btn btn-info">Back to Plan List</Link>
      </Container>
    );
  }

  return (
    <Container fluid className="main-content">
      <Row className="page-title-box d-flex align-items-center justify-content-between">
        <h4 className="mb-0 text-info">VIEW SUBSCRIPTION PLAN</h4>
        {/* <Breadcrumb>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>Home</Breadcrumb.Item>
          <Breadcrumb.Item active>View Subscription Plan</Breadcrumb.Item>
        </Breadcrumb> */}
      </Row>
      <Row>
        <Col xl={12}>
          <Card className="custom-shadow rounded-lg border my-2">
            <Card.Body>
              <h5 className="h6 text-uppercase mb-3">Subscription Plan Information</h5>
              <Row>
                {[
                  { label: "Plan Name", value: plan.name },
                  { label: "Price Per Month", value: `$${plan.price}` },
                  { label: "Leads Per Month", value: plan.leads },
                  { label: "Additional Lead Price", value: `$${plan.additionalLeadPrice}` },
                  { label: "Duration", value: capitalizeFirstLetter(plan.duration) },
                  { label: "Product ID", value: plan.productId },
                  { label: "Plan ID", value: plan.priceId },
                  { label: "Description", value: plan.description },
                  { label: "Created At", value: new Date(plan.createdAt).toLocaleString() },
                  { label: "Updated At", value: new Date(plan.updatedAt).toLocaleString() }
                ].map((item, index) => (
                  <Col lg={6} key={index} className="mb-3">
                    <strong>{item.label}:</strong> {item.value}
                  </Col>
                ))}
              </Row>
              <div className="text-end">
                <Button variant="outline-info" as={Link} to="/planList">Back</Button>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ViewSubscriptionPlan;
