import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { AiOutlineArrowLeft } from 'react-icons/ai'
import { Card, Col, Container, Image, Row, Spinner } from 'react-bootstrap'
import { Constants } from 'src/Constants'

const CustomerDetails = () => {
  const { id } = useParams()
  const [userData, setUserData] = useState(null)
  const navigate = useNavigate()

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await fetch(Constants.getUserById + id)
        if (response.ok) {
          const data = await response.json()
          setUserData(data)
        } else {
          console.error('Failed to fetch user details')
        }
      } catch (error) {
        console.error('Error fetching user details:', error)
      }
    }

    fetchUserDetails()
  }, [id])

  if (!userData) {
   
    return <div> <Spinner animation="border" /></div>
  }

  return (
    <Container fluid>
      <Row>
        <Col xs={12}>
          <div className="d-flex align-items-center justify-content-between mb-3">
            <h4 className="mb-0 text-info">
              {' '}
              <AiOutlineArrowLeft
                onClick={() => {
                  navigate(-1)
                }}
                className="mx-1"
              />{' '}
              USER DETAILS
            </h4>
          </div>
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <Card className="rounded-lg border-0 custom-shadow">
            <Row className="g-0">
              <Col xs={12} md={4} lg={3}>
               
              </Col>
              <Col xs={12} md={8} lg={9}>
                <Card.Body>
                  <dl className="row">
                    <dt className="col-sm-4 col-lg-3">Name:</dt>
                    <dd className="col-sm-8 col-lg-9">
                      {userData?.firstName} {userData?.lastName}
                    </dd>
                   
                    

                    <dt className="col-sm-4 col-lg-3">Contact Number:</dt>
                    <dd className="col-sm-8 col-lg-9">{userData?.contactNumbers}</dd>

                    <dt className="col-sm-4 col-lg-3">Email:</dt>
                    <dd className="col-sm-8 col-lg-9">{userData?.emailAddresses}</dd>

                  
                  </dl>
                </Card.Body>
              </Col>
             
            </Row>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default CustomerDetails
