import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Container, Row, Col, Card, Button,Spinner  } from 'react-bootstrap'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Constants, imageUrl } from 'src/Constants'

const MySwal = withReactContent(Swal)

const ViewCar = () => {
  const { id } = useParams()
  const [carDetails, setCarDetails] = useState(null)

  useEffect(() => {
    const fetchCarData = async () => {
      try {
        const response = await fetch(Constants.getCarById + id)
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`)
        }
        const data = await response.json()
        setCarDetails(data)
      } catch (error) {
        console.error('Error fetching car data:', error.message)
      }
    }

    fetchCarData()
  }, [id])

  const handleGoBack = () => {
    window.history.back()
  }

  return (
    <div className="main-content">
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0 text-info">VIEW CAR</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <Link to="/dashboard">Home</Link>
                    </li>
                    <li className="breadcrumb-item active text-info">View Car</li>
                  </ol>
                </div>
              </div>
            </Col>
          </Row>

          <Row className="m-2">
            <Col xs={12}>
              <div className="card custom-shadow rounded-lg border">
                <div className="card-body">
                  {carDetails ? (
                    <Card>
                      
                      <Card.Header>
                        <h3>{`${carDetails?.make?.name} ${carDetails?.model} ${carDetails?.trim}`}</h3>
                      </Card.Header>
                      <Card.Body>
                        <Row>
                          <Col md={6}>
                            <img
                              // src={`http://localhost:6060/static/${carDetails.make.bannerImage}`}
                              src={`${imageUrl+carDetails?.make?.bannerImage}`}
                              alt={`${carDetails?.make.name} Banner`}
                              className="img-fluid"
                            />
                          </Col>
                          <Col md={6}>
                          <p> <strong>Vehicle Type :</strong>  {carDetails?.vehicleType?.name}</p>
                            <p> <strong>Year :</strong>  {carDetails?.year}</p>
                            <p><strong>Type :</strong> {carDetails?.type}</p>
                            <p><strong>Milage :</strong> {carDetails?.mileage}</p>
                            <p><strong>Rust Proofing :</strong> {carDetails?.rustProofing}</p>
                            <p><strong>Rust Proofing Details :</strong> {carDetails?.rustProofingDetails}</p>
                            <p> <strong>Paint Protection :</strong> {carDetails?.paintProtection}</p>
                            <p> <strong>Paint Protection Details :</strong> {carDetails?.paintProtectionDetails} </p>
                            <p><strong>Fabric Protection  :</strong>{carDetails?.fabricProtection}</p>
                            <p> <strong>Fabric Protection Details :</strong> {carDetails?.fabricProtectionDetails}</p>
                          </Col>
                          <Col md={6}>
                            <p><strong>Window Tinting :</strong> {carDetails?.windowTinting}</p>
                            <p><strong>Window Tinting Details :</strong>{carDetails?.windowTintingDetails}</p>
                            <p><strong>Other Protection Packages :</strong>{carDetails?.otherProtectionPackages}</p>
                            <p><strong>other Protection Package Details :</strong>{carDetails?.otherProtectionPackagesdetails}</p>
                            <p><strong>Comprehensive Warranty :</strong>{carDetails?.comprehensiveWarranty}</p>
                            <p> <strong>Comprehensive Warranty Details :</strong> {carDetails?.comprehensiveWarrantyDetails}</p>
                            <p><strong>Power Train Warranty :</strong>{carDetails?.powerTrainWarranty}</p>
                            <p><strong>Power Train Warranty Details :</strong>{carDetails?.powerTrainWarrantyDetails}</p>
                            <p><strong>Total Insurance :</strong>{carDetails?.totalInsurance}</p>
                            <p><strong>Total Insurance Details :</strong>{carDetails?.totalInsuranceDetails}</p>
                            <p><strong>Estimated Total Taxes & Fees :</strong>{carDetails?.estimatedTotalTaxesAndFees}</p>
                            <p><strong>Estimated Total Taxes & Fees details :</strong>{carDetails?.estimatedTotalTaxesAndFeesDetails}</p>
                            <p> <strong>Admin Fee :</strong>{carDetails?.adminFee}</p>
                            <p><strong>Admin Fee Details :</strong>{carDetails?.adminFeeDetails}</p>
                            <p><strong>AirTax :</strong>{carDetails?.airTax}</p>
                            <p><strong>AirTax details :</strong>{carDetails?.airTaxdetails}</p>
                            <p><strong>Gasoline :</strong>{carDetails?.gasoline}</p>
                            <p><strong>Gasoline Details :</strong>{carDetails?.gasolineDetails}</p>
                            <p><strong>OMVIC Fee :</strong>{carDetails?.omvicFee}</p>
                            <p><strong>OMVIC Fee Details :</strong>{carDetails?.omvicFeedetails}</p>
                            <p><strong>Lien Registration Fee :</strong>{carDetails?.lienRegistrationFee}</p>
                            <p><strong>Lien registration Fee Details :</strong>{carDetails?.lienRegistrationFeeDetails}</p>
                           
                            
                            
                          </Col>
                          <Col md={6}>
                          <p><strong>License Fee :</strong>{carDetails?.licenseFee}</p>
                            <p><strong>License Fee Details :</strong>{carDetails?.licenseFeeDetails}</p>
                            <p><strong>Other :</strong>{carDetails?.other}</p>
                            <p><strong>Other Details :</strong>{carDetails?.otherDetails}</p>
                            <p><strong>Payable On Delivery :</strong>{carDetails?.payableOnDelivery}</p>
                            <p><strong>Payable On Delivery Details :</strong>{carDetails?.payableOnDeliveryDetails}</p>
                            <p><strong>Tax Rate :</strong>{carDetails?.taxRate}</p>
                            <p><strong>Tax Rate Percentage :</strong>{carDetails?.taxRatePercentage}</p>
                            <p><strong>Note :</strong>{carDetails?.note}</p>
                            <p><strong>Tire and Wheel :</strong>{carDetails?.tireAndWheel}</p>
                            <p><strong>Tire and Wheel Details :</strong>{carDetails?.tireAndWheelDetails}</p>
                            <p><strong>Side Steps :</strong>{carDetails?.sideSteps}</p>
                            <p><strong>Side Steps Details :</strong>{carDetails?.sideStepsdetails}</p>
                            <p><strong>Roof Racks :</strong>{carDetails?.roofRacks}</p>
                            <p><strong>Roof Racks Details :</strong>{carDetails?.roofRacksDetails}</p>
                            <p><strong>Trailer Packages :</strong>{carDetails?.trailerPackages}</p>
                            <p><strong>Trailer Packages Details :</strong>{carDetails?.trailerPackagesdetails}</p>
                            <p><strong>Spray Liner :</strong>{carDetails?.sprayLiner}</p>
                            <p><strong>Spray Liner Details :</strong>{carDetails?.sprayLinerdetails}</p>
                          </Col>
                        </Row>
                      </Card.Body>
                      <Card.Footer>
                        <Button variant="secondary" onClick={handleGoBack} className="ml-2">
                          Go Back
                        </Button>
                      </Card.Footer>
                    </Card>
                  ) : (
                    <div className="text-center">
                            <Spinner animation="border" variant="primary" />
                          </div>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default ViewCar
