import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Container, Row, Col, Card, Button, Spinner } from 'react-bootstrap';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import axios from 'axios';

const MySwal = withReactContent(Swal);

const ViewDealerCar = () => {
  const { id } = useParams();
  const [carDetails, setCarDetails] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCarDetails = async () => {
      try {
        const response = await axios.get(`https://api.icarbuyer.co/api/dealer/getCarModelDetailsById/${id}`);
        setCarDetails(response.data.carModelDetails);
      } catch (error) {
        MySwal.fire('Error', 'Failed to fetch car details', 'error');
      } finally {
        setLoading(false);
      }
    };

    fetchCarDetails();
  }, [id]);

  const handleGoBack = () => {
    window.history.back();
  };

  const cardStyle = {
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    borderRadius: '10px',
    background: '#f0f0f0',
    padding: '20px',
  };

  const titleStyle = {
    marginBottom: '20px',
    color: '#5c6b84',
    textShadow: '1px 1px 2px rgba(0, 0, 0, 0.1)',
  };

  const detailStyle = {
    marginBottom: '10px',
    fontSize: '16px',
    color: '#3c4b64',
  };

  const mainContainerStyle = {
    padding: '20px',
  };

  const headerStyle = {
    background: 'linear-gradient(to right, #3c4b64, #5c6b84)',
    padding: '10px',
    borderRadius: '10px',
    color: '#fff',
  };

  const goBackButtonStyle = {
    background: '#3c4b64',
    borderColor: '#3c4b64',
    color: '#fff',
    marginTop: '20px',
  };

  return (
    <div className="main-content">
      <div className="page-content">
        <Container fluid style={mainContainerStyle}>
          <Row>
            <Col>
              <div className="page-title-box d-flex align-items-center justify-content-between" style={headerStyle}>
                <h4 className="mb-0" style={{ color: '#ffffff', textShadow: '1px 1px 2px rgba(0, 0, 0, 0.2)' }}>View Dealer Car Details</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <Link to="/dashboard" style={{ color: '#fff' }}>Home</Link>
                    </li>
                    <li className="breadcrumb-item active" style={{ color: '#fff' }}>View Dealer Car Details</li>
                  </ol>
                </div>
              </div>
            </Col>
          </Row>

          <Row className="m-2">
            <Col xs={12}>
              <Card style={cardStyle}>
                <Card.Body>
                  {loading ? (
                    <div className="text-center">
                      <Spinner animation="border" role="status">
                        <span className="sr-only"></span>
                      </Spinner>
                    </div>
                  ) : (
                    carDetails && (
                      <div className="car-details">
                        <h5 style={titleStyle}>Car Model Details</h5>
                        <Row className="mb-3">
                          <Col>
                            <p style={detailStyle}><strong>Make:</strong> {carDetails?.make}</p>
                            <p style={detailStyle}><strong>Vehicle Type:</strong> {carDetails?.vehicleType}</p>
                            <p style={detailStyle}><strong>Model Name:</strong> {carDetails?.modelName}</p>
                            <p style={detailStyle}><strong>Trim:</strong> {carDetails?.trim}</p>
                            <p style={detailStyle}><strong>Year:</strong> {carDetails?.year}</p>
                            <p style={detailStyle}><strong>Type:</strong> {carDetails?.type}</p>
                            <p style={detailStyle}><strong>Mileage:</strong> {carDetails?.mileage}</p>
                          </Col>
                          <Col>
                            <p style={detailStyle}><strong>Rust Proofing Details:</strong> {carDetails?.rustProofingDetails}</p>
                            <p style={detailStyle}><strong>Paint Protection Details:</strong> {carDetails?.paintProtectionDetails}</p>
                            <p style={detailStyle}><strong>Fabric Protection Details:</strong> {carDetails?.fabricProtectionDetails}</p>
                            <p style={detailStyle}><strong>Window Tinting Details:</strong> {carDetails?.windowTintingDetails}</p>
                            <p style={detailStyle}><strong>Other Protection Packages:</strong> {carDetails?.otherProtectionPackagesdetails}</p>
                            <p style={detailStyle}><strong>Comprehensive Warranty:</strong> {carDetails?.comprehensiveWarrantyDetails}</p>
                            <p style={detailStyle}><strong>Power Train Warranty:</strong> {carDetails?.powerTrainWarrantyDetails}</p>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col>
                            <p style={detailStyle}><strong>Total Insurance:</strong> {carDetails?.totalInsuranceDetails}</p>
                            <p style={detailStyle}><strong>Estimated Total Taxes and Fees:</strong> {carDetails?.estimatedTotalTaxesAndFeesDetails}</p>
                            <p style={detailStyle}><strong>Admin Fee:</strong> {carDetails?.adminFeeDetails}</p>
                            <p style={detailStyle}><strong>Air Tax:</strong> {carDetails?.airTaxdetails}</p>
                            <p style={detailStyle}><strong>Gasoline:</strong> {carDetails?.gasolineDetails}</p>
                            <p style={detailStyle}><strong>OMVIC Fee:</strong> {carDetails?.omvicFeedetails}</p>
                            <p style={detailStyle}><strong>Lien Registration Fee:</strong> {carDetails?.lienRegistrationFeeDetails}</p>
                          </Col>
                          <Col>
                            <p style={detailStyle}><strong>License Fee:</strong> {carDetails?.licenseFeeDetails}</p>
                            <p style={detailStyle}><strong>Other Details:</strong> {carDetails?.otherDetails}</p>
                            <p style={detailStyle}><strong>Payable On Delivery:</strong> {carDetails?.payableOnDeliveryDetails}</p>
                            <p style={detailStyle}><strong>Tax Rate:</strong> {carDetails?.taxRate} ({carDetails?.taxRatePercentage}%)</p>
                            <p style={detailStyle}><strong>Note:</strong> {carDetails?.note}</p>
                            <p style={detailStyle}><strong>Tire and Wheel Details:</strong> {carDetails?.tireAndWheelDetails}</p>
                            <p style={detailStyle}><strong>Side Steps:</strong> {carDetails?.sideStepsdetails}</p>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <p style={detailStyle}><strong>Roof Racks:</strong> {carDetails?.roofRacksDetails}</p>
                            <p style={detailStyle}><strong>Trailer Packages:</strong> {carDetails?.trailerPackagesdetails}</p>
                            <p style={detailStyle}><strong>Spray Liner:</strong> {carDetails?.sprayLinerdetails}</p>
                            <p style={detailStyle}><strong>Base Price:</strong> ${carDetails?.basePrice}</p>
                          </Col>
                        </Row>
                        <Button style={goBackButtonStyle} onClick={handleGoBack} className="ml-2 mb-3">
                          Go Back
                        </Button>
                      </div>
                    )
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default ViewDealerCar;
