import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { FaEye, FaRegEdit, FaTrashAlt, FaCopy, FaEdit, FaRegCopy } from 'react-icons/fa'
import Switch from 'react-switch'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {
  Container,
  Row,
  Col,
  Table,
  Button,
  InputGroup,
  FormControl,
  Pagination,
  Dropdown,
  Spinner,
} from 'react-bootstrap'
import { Constants } from 'src/Constants'

const MySwal = withReactContent(Swal)

const DealerCarList = () => {
  const { id } = useParams()
  const [carList, setCarList] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchCarDetails = async () => {
      setLoading(true)
      try {
        const response = await fetch(`https://api.icarbuyer.co/api/dealer/allCarModelDetails/${id}`)
        const data = await response.json()
        setCarList(data.data)
      } catch (error) {
        console.error('Error fetching car details:', error)
      } finally {
        setLoading(false)
      }
    }

    fetchCarDetails()
  }, [id])

  const handleSearch = (event) => {
    setSearchTerm(event.target.value)
    setCurrentPage(1)
  }

  const indexOfLastItem = currentPage * itemsPerPage
  const indexOfFirstItem = indexOfLastItem - itemsPerPage
  const currentItems = carList.slice(indexOfFirstItem, indexOfLastItem)

  // Pagination Calculations
  const totalPages = Math.ceil(carList.length / itemsPerPage)
  const pageRange = 3 // Number of page numbers to display around the current page
  const startPage = Math.max(1, currentPage - pageRange)
  const endPage = Math.min(totalPages, currentPage + pageRange)

  // Handle page click
  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber)
  }

  // Handle dropdown selection
  const handleItemsPerPageChange = (selectedItemsPerPage) => {
    setItemsPerPage(selectedItemsPerPage)
    setCurrentPage(1) // Reset to first page when changing items per page
  }
  const handleDeleteCar = async (carId) => {
    try {
      const response = await fetch(
        `https://api.icarbuyer.co/api/dealer/deleteCarModelDetails/${carId}`,
        {
          method: 'DELETE',
        },
      )
      const data = await response.json()
      if (response.ok) {
        MySwal.fire({
          icon: 'success',
          title: 'Success',
          text: data.message,
        })
        // Remove deleted car from carList state
        setCarList(carList.filter((car) => car._id !== carId))
      } else {
        throw new Error(data.message || 'Failed to delete car')
      }
    } catch (error) {
      console.error('Error deleting car:', error)
      MySwal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to delete car. Please try again later.',
      })
    }
  }

  return (
    <div className="main-content">
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0 text-info">DEALER VEHICLE DETAILS</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <Link to="/dashboard">Home</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to="/customerList">Dealer List</Link>
                    </li>
                    <li className="breadcrumb-item active text-info">Dealer Vehicle List</li>
                  </ol>
                </div>
              </div>
            </Col>
          </Row>
          {loading ? (
            <div className="d-flex justify-content-center align-items-center vh-100">
              <Spinner animation="border" role="status">
                <span className="sr-only"></span>
              </Spinner>
            </div>
          ) : (
            <Row className="m-2">
              <Col xs={12}>
                <div className="card custom-shadow rounded-lg border">
                  <div className="card-body">
                    <Row>
                      <Col xs={6} className="mb-3">
                        <Button variant="info" as={Link} to={`/Add-Dealer-Car-Details/${id}`}>
                          Add New Car
                        </Button>
                      </Col>

                      <Col xs={6} style={{ width: '50%' }}>
                        <InputGroup className="mb-3">
                          <FormControl
                            placeholder="Search by make, model, trim, vehicle type, year or type."
                            value={searchTerm}
                            onChange={handleSearch}
                          />
                        </InputGroup>
                      </Col>
                    </Row>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="d-flex justify-content-between align-items-center">
                        <span className="mx-1">Show </span>
                        <Dropdown onSelect={handleItemsPerPageChange} className="mx-1">
                          <Dropdown.Toggle variant="info" id="dropdown-basic">
                            {itemsPerPage}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item eventKey={10}>10</Dropdown.Item>
                            <Dropdown.Item eventKey={25}>25</Dropdown.Item>
                            <Dropdown.Item eventKey={50}>50</Dropdown.Item>
                            <Dropdown.Item eventKey={100}>100</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                        <span> items per page</span>
                      </div>
                      <div>
                        <Pagination>
                          <Pagination.First onClick={() => handlePageClick(1)} />
                          <Pagination.Prev
                            onClick={() => handlePageClick(currentPage - 1)}
                            disabled={currentPage === 1}
                          />
                          {[...Array(endPage - startPage + 1).keys()].map((index) => (
                            <Pagination.Item
                              key={startPage + index}
                              active={startPage + index === currentPage}
                              onClick={() => handlePageClick(startPage + index)}
                            >
                              {startPage + index}
                            </Pagination.Item>
                          ))}
                          <Pagination.Next
                            onClick={() => handlePageClick(currentPage + 1)}
                            disabled={currentPage === totalPages}
                          />
                          <Pagination.Last onClick={() => handlePageClick(totalPages)} />
                        </Pagination>
                      </div>
                    </div>
                    <Table striped bordered responsive>
                      <thead>
                        <tr>
                          <th>Model</th>
                          <th>Make</th>
                          <th>Vehicle Type</th>
                          <th>Trim</th>
                          <th>Year</th>
                          <th>Type</th>
                          <th>Base Price</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentItems
                          ?.filter(
                            (car) =>
                              car?.make.toLowerCase().includes(searchTerm.toLowerCase()) ||
                              car?.modelName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                              car?.trim.toLowerCase().includes(searchTerm.toLowerCase()) ||
                              car?.year.toString().includes(searchTerm) ||
                              car?.vehicleType.toLowerCase().includes(searchTerm.toLowerCase()) ||
                              car?.type.toLowerCase().includes(searchTerm.toLowerCase()),
                          )
                          .map((car) => (
                            <tr key={car?._id}>
                              <td>{car?.modelName}</td>
                              <td>{car?.make}</td>
                              <td>{car?.vehicleType}</td>
                              <td>{car?.trim}</td>
                              <td>{car?.year}</td>
                              <td>{car?.type}</td>
                              <td>{car?.basePrice}</td>
                              <td>
                                <Link  to={`/viewDealerCar/${car?._id}`} title='View Car Details'>
                                  <Button variant="outline-info" size="sm" className='mx-2'>
                                    <FaEye />
                                  </Button>
                                </Link>
                                <Link to={`/Add-Dealer-Car-Details-Copy/${car?._id}/${id}`} title='Copy Car Details'>
                                  <Button variant="outline-info" size="sm" className="">
                                    <FaRegCopy />
                                  </Button>
                                </Link>
                                <Link to={`/Edit-Car-Details/${car?._id}/${id}`} title='Edit Car Details'>
                                  <Button variant="outline-info" size="sm" className="mx-2">
                                    <FaRegEdit />
                                  </Button>
                                </Link>

                                <Button
                                  variant="outline-danger"
                                  size="sm"
                                  onClick={() => handleDeleteCar(car._id)}
                                  title='Delete Car Record'
                                >
                                  <FaTrashAlt />
                                </Button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </div>
  )
}

export default DealerCarList
