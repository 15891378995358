import React, { useState, useCallback, useEffect } from 'react';
import { FaEye, FaCarAlt, FaLocationArrow, FaClipboardList } from 'react-icons/fa';
import Switch from 'react-switch';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Container, Row, Col, Table, Button, Pagination, Modal, Form } from 'react-bootstrap';
import { Constants } from 'src/Constants';
import { AiOutlineArrowLeft, AiOutlineArrowRight } from 'react-icons/ai';

const MySwal = withReactContent(Swal);

const CustomerList = () => {
  const [customers, setCustomers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const location = useLocation();

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [sortColumn, setSortColumn] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');

  const [showModal, setShowModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const response = await fetch(Constants.getAllDealers);
        if (response.ok) {
          const data = await response.json();
          setCustomers(data);
        } else {
          console.error('Failed to fetch customers');
        }
      } catch (error) {
        console.error('Error fetching customers:', error);
      }
    };

    fetchCustomers();
  }, []);

  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
    } else {
      setSortColumn(column);
      setSortOrder('asc');
    }
  };

  const sortedCustomers = customers.sort((a, b) => {
    const columnA = a[sortColumn];
    const columnB = b[sortColumn];

    if (columnA && columnB) {
      const valueA = typeof columnA === 'string' ? columnA : String(columnA);
      const valueB = typeof columnB === 'string' ? columnB : String(columnB);

      if (sortOrder === 'asc') {
        return valueA.localeCompare(valueB);
      } else {
        return valueB.localeCompare(valueA);
      }
    }

    return 0;
  });

  const filteredCustomers = sortedCustomers.filter((customer) =>
    `${customer.firstName} ${customer.lastName} ${customer.email} ${customer.company} ${customer.status} ${customer.carCompany}`
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredCustomers.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleStatusToggle = useCallback((id, currentStatus) => {
    fetch(`${Constants.updateDealerStatus}${id}/status`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ status: currentStatus === 'active' ? 'inactive' : 'active' }),
    })
      .then((response) => {
        if (response.ok) {
          setCustomers((prevCustomers) =>
            prevCustomers.map((customer) =>
              customer._id === id
                ? { ...customer, status: currentStatus === 'active' ? 'inactive' : 'active' }
                : customer
            )
          );
          MySwal.fire('Updated!', 'Dealer status has been updated.', 'success');
        } else {
          throw new Error('Failed to update Dealer status.');
        }
      })
      .catch((error) => {
        console.error('Error updating Dealer status:', error);
        MySwal.fire('Error', 'Failed to update Dealer status.', 'error');
      });
  }, []);

  const renderPaginationItems = () => {
    const totalPages = Math.ceil(customers.length / itemsPerPage);
    const pageNumbers = [];

    let startPage, endPage;
    if (totalPages <= 5) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (currentPage <= 3) {
        startPage = 1;
        endPage = 5;
      } else if (currentPage + 2 >= totalPages) {
        startPage = totalPages - 4;
        endPage = totalPages;
      } else {
        startPage = currentPage - 2;
        endPage = currentPage + 2;
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <Pagination.Item key={i} active={i === currentPage} onClick={() => paginate(i)}>
          {i}
        </Pagination.Item>
      );
    }

    if (startPage > 1) {
      pageNumbers.unshift(
        <Pagination.Item key="prev" onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}>
          <AiOutlineArrowLeft />
        </Pagination.Item>,
        <Pagination.Item key="first" onClick={() => paginate(1)}>
          1
        </Pagination.Item>,
        <Pagination.Ellipsis key="ellipsis-prev" />
      );
    }

    if (endPage < totalPages) {
      pageNumbers.push(
        <Pagination.Ellipsis key="ellipsis-next" />,
        <Pagination.Item key="last" onClick={() => paginate(totalPages)}>
          {totalPages}
        </Pagination.Item>,
        <Pagination.Item key="next" onClick={() => paginate(currentPage + 1)} disabled={currentPage === totalPages}>
          <AiOutlineArrowRight />
        </Pagination.Item>
      );
    }

    return pageNumbers;
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleFileUpload = async () => {
    if (!selectedFile) {
      MySwal.fire('Error', 'Please select a file.', 'error');
      return;
    }

    const formData = new FormData();
    formData.append('file', selectedFile);

    try {
      const response = await fetch('https://api.icarbuyer.co/api/dealer/upload-dealers', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        const result = await response.json();
        MySwal.fire('Success', 'Dealers uploaded successfully.', 'success');
        setShowModal(false);
        // Optionally, you can refetch the customer list here if needed
      } else {
        throw new Error('Failed to upload file.');
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      MySwal.fire('Error', 'Failed to upload file.', 'error');
    }
  };

  return (
    <div className="main-content">
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0 text-info">DEALER LIST</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <Link to="/dashboard">Home</Link>
                    </li>
                    <li className="breadcrumb-item text-info active">Dealer List</li>
                  </ol>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="card custom-shadow rounded-lg border my-3">
                <div className="card-body">
                  <h5 className="h6 text-uppercase mb-3">Dealer Account List </h5>
                  <Row>
                    <Col md={4} xs={12} className="mb-3 text-center">
                      <h3 className="fw-bolder text-info mb-0">{customers.length}</h3>
                      <p className="text-secondary">Total Dealers</p>
                    </Col>
                  </Row>

                  <div className="dataTables_length mb-2 d-flex">
                    <div className="search-box">
                      <input
                        type="text"
                        id="search"
                        placeholder="Search "
                        name="search"
                        className="form-control "
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                    </div>
                    <Button className="ml-2" variant="info" onClick={() => setShowModal(true)}>
                      Upload Dealer CSV
                    </Button>
                  </div>

                  <div className="table-responsive">
                    <Table responsive bordered hover>
                      <thead>
                        <tr>
                          <th onClick={() => handleSort('country')}>
                            Name of Dealership
                            {sortColumn === 'country' && <span>{sortOrder === 'asc' ? ' 🔽' : ' 🔼'}</span>}
                          </th>
                          <th onClick={() => handleSort('firstName')}>
                            Category
                            {sortColumn === 'firstName' && <span>{sortOrder === 'asc' ? ' 🔽' : ' 🔼'}</span>}
                          </th>
                          <th onClick={() => handleSort('lastName')}>
                            Telephone No.
                            {sortColumn === 'lastName' && <span>{sortOrder === 'asc' ? ' 🔽' : ' 🔼'}</span>}
                          </th>
                          <th onClick={() => handleSort('email')}>
                            Email
                            {sortColumn === 'email' && <span>{sortOrder === 'asc' ? ' 🔽' : ' 🔼'}</span>}
                          </th>
                          <th onClick={() => handleSort('leadBalanceWallet')}>
                          Lead Balance
                            {sortColumn === 'leadBalanceWallet' && <span>{sortOrder === 'asc' ? ' 🔽' : ' 🔼'}</span>}
                          </th>
                          <th>Status</th>
                          <th>Actions</th>
                        </tr>
                      </thead>

                      <tbody>
                        {currentItems?.map((customer, index) => (
                          <tr key={customer?._id}>
                           <td>{customer?.businessName.replace(/_/g, " ")}</td>
                            <td>{customer?.category}</td>
                            <td>{customer?.phone}</td>
                            <td>{customer?.email}</td>
                            <td>{customer?.leadBalanceWallet}</td>
                            <td>
                              <Switch
                                onChange={() => handleStatusToggle(customer._id, customer.status)}
                                checked={customer.status === 'active'}
                                onColor="#86d38a"
                                offColor="#e44d4d"
                                height={20}
                                width={40}
                              />
                            </td>
                            <td>
                              <div className="icon-container">
                                <Link
                                  className="btn btn-sm btn-info text-white"
                                  title="Add Makes & Vehicle Types"
                                  to={`/dealerAction/${customer._id}`}
                                >
                                  <FaClipboardList />
                                </Link>
                                <Link
                                  className="btn btn-sm btn-info text-white"
                                  title="Add Cars"
                                  to={`/addDealerCar/${customer._id}`}
                                >
                                  <FaCarAlt />
                                </Link>
                                <Link
                                  className="btn btn-sm btn-info text-white"
                                  title="Dealer Car Details"
                                  to={`/Dealer-Car-Lists/${customer._id}`}
                                >
                                  <FaCarAlt />
                                </Link>
                                <Link
                                  className="btn btn-sm btn-info text-white"
                                  title="Add Provinces & Postal Codes"
                                  to={`/addDealerPostCodes/${customer._id}`}
                                >
                                  <FaLocationArrow />
                                </Link>
                                <Link
                                  className="btn btn-sm btn-info text-white"
                                  title="View Dealer Details"
                                  to={`/viewDealer/${customer._id}`}
                                >
                                  <FaEye />
                                </Link>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                  <Container>
                    <div className="col-sm-12 col-md-5">
                      <div className="dataTables_length">
                        Showing {indexOfFirstItem + 1} to {Math.min(indexOfLastItem, customers.length)} of {customers.length} entries
                      </div>
                    </div>
                    <Pagination>{renderPaginationItems()}</Pagination>
                  </Container>
                </div>
              </div>
            </Col>
          </Row>
        </Container>

        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Upload Dealer CSV</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="formFile">
                <Form.Label>Choose CSV file</Form.Label>
                <Form.Control type="file" onChange={handleFileChange} />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              Close
            </Button>
            <Button variant="primary" onClick={handleFileUpload}>
              Upload
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default CustomerList;
