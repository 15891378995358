import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { FaEye, FaRegEdit, FaSearch, FaTrashAlt, FaShareAlt } from 'react-icons/fa'
import Switch from 'react-switch'
import Swal from 'sweetalert2'

import withReactContent from 'sweetalert2-react-content'
import {
  Container,
  Row,
  Col,
  Table,
  Button,
  InputGroup,
  FormControl,
  Pagination,
  Spinner,
  Form,
} from 'react-bootstrap'
import { Constants } from 'src/Constants'

const MySwal = withReactContent(Swal)

const LeadSharings = () => {
  const [leads, setLeads] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [keyword, setKeyword] = useState('')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [limit] = useState(10) // You can make this dynamic if needed

  // Function to fetch leads from the API
  useEffect(() => {
    const fetchLeads = async () => {
      setLoading(true)
      setError(null)

      try {
        const params = new URLSearchParams({
          keyword,
          page: currentPage,
          limit,
          startDate,
          endDate,
        })

        const response = await fetch(
          `https://api.icarbuyer.co/api/all-leads?${params.toString()}`,
          {
            method: 'GET',
          },
        )

        // Parse the response regardless of the HTTP status
        const data = await response.json()

        if (!response.ok) {
          // If the API provides a message, use it; otherwise, use the HTTP status text
          throw new Error(data.message || `Error: ${response.status} ${response.statusText}`)
        }

        if (data.status !== 1) {
          // If API response status is not 1, treat it as an error
          throw new Error(data.message || 'Failed to fetch leads.')
        }

        setLeads(data.leads)
        setTotalPages(data.pagination.totalPages)
      } catch (err) {
        setError(err.message)
      } finally {
        setLoading(false)
      }
    }

    fetchLeads()
    // Dependency array includes all variables that trigger data fetching
  }, [currentPage, keyword, startDate, endDate, limit])

  // Handle search form submission
  const handleSearch = (e) => {
    e.preventDefault()
    setCurrentPage(1) // Reset to first page on new search
    // Data fetching is triggered by useEffect
  }

  // Handle pagination click
  const handlePageChange = (pageNumber) => {
    if (pageNumber !== currentPage) {
      setCurrentPage(pageNumber)
    }
  }

  // Render pagination items
  const renderPagination = () => {
    let items = []

    // Determine the range of pages to display
    const maxPagesToShow = 5
    let startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2))
    let endPage = startPage + maxPagesToShow - 1

    if (endPage > totalPages) {
      endPage = totalPages
      startPage = Math.max(1, endPage - maxPagesToShow + 1)
    }

    for (let number = startPage; number <= endPage; number++) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === currentPage}
          onClick={() => handlePageChange(number)}
        >
          {number}
        </Pagination.Item>,
      )
    }

    return (
      <Pagination>
        <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1} />
        <Pagination.Prev
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        />
        {items}
        <Pagination.Next
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        />
        <Pagination.Last
          onClick={() => handlePageChange(totalPages)}
          disabled={currentPage === totalPages}
        />
      </Pagination>
    )
  }

  // Handle delete action
  const handleDelete = (leadId) => {
    MySwal.fire({
      title: 'Are you sure?',
      text: 'Do you want to delete this lead?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        // Implement delete API call here
        // For example:
        /*
        fetch(`https://api.icarbuyer.co/api/leads/${leadId}`, {
          method: 'DELETE',
        })
          .then(response => response.json())
          .then(data => {
            if (data.status === 1) {
              MySwal.fire('Deleted!', 'Lead has been deleted.', 'success')
              fetchLeads() // Refresh the leads list
            } else {
              throw new Error(data.message || 'Failed to delete lead.')
            }
          })
          .catch(err => {
            MySwal.fire('Error!', err.message, 'error')
          })
        */
        // Since the API details for deletion are not provided, we'll show a success message
        MySwal.fire('Deleted!', 'Lead has been deleted.', 'success')
      }
    })
  }

  return (
    <div className="main-content">
      <div className="page-content">
        <Container fluid>
          {/* Page Title and Breadcrumb */}
          <Row>
            <Col>
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0 text-info">Lead Sharings</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <Link to="/dashboard">Home</Link>
                    </li>
                    <li className="breadcrumb-item active text-info">Lead Sharings</li>
                  </ol>
                </div>
              </div>
            </Col>
          </Row>

          {/* Search and Filter Section */}
          <Row className="mt-4">
            <Col>
              <Form onSubmit={handleSearch}>
                <Row className="align-items-end">
                  {/* Keyword Search */}
                  <Col md={4}>
                    <Form.Group controlId="searchKeyword">
                      <Form.Label>Search Keyword</Form.Label>
                      <InputGroup>
                        <FormControl
                          type="text"
                          placeholder="Enter keyword..."
                          value={keyword}
                          onChange={(e) => setKeyword(e.target.value)}
                        />
                        <Button variant="primary" type="submit">
                          <FaSearch />
                        </Button>
                      </InputGroup>
                    </Form.Group>
                  </Col>

                  {/* Start Date */}
                  <Col md={3}>
                    <Form.Group controlId="startDate">
                      <Form.Label>Start Date</Form.Label>
                      <Form.Control
                        type="date"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                      />
                    </Form.Group>
                  </Col>

                  {/* End Date */}
                  <Col md={3}>
                    <Form.Group controlId="endDate">
                      <Form.Label>End Date</Form.Label>
                      <Form.Control
                        type="date"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                      />
                    </Form.Group>
                  </Col>

                  {/* Search Button */}
                </Row>
              </Form>
            </Col>
          </Row>

          {/* Leads Table */}
          <Row className="mt-4">
            <Col>
              {loading ? (
                <div className="text-center my-5">
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>
              ) : error ? (
                <div className="alert alert-danger" role="alert">
                  {error}
                </div>
              ) : leads.length === 0 ? (
                <div className="alert alert-info" role="alert">
                  No leads found.
                </div>
              ) : (
                <Table striped bordered hover responsive>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Enquiry ID</th>
                      <th>Make</th>
                      <th>Finance Type</th>
                      <th>Dealer</th>
                      <th>Email</th>
                      <th>Phone</th>
                      <th>Payment Status</th> {/* NEW */}
                      <th>Payment Method</th> {/* NEW */}
                      <th>Sent Date</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {leads?.map((lead, index) => (
                      <tr key={lead._id}>
                        <td>{(currentPage - 1) * limit + index + 1}</td>
                        <td>{lead?.enquiryId?._id}</td>
                        <td>{lead?.enquiryId?.make}</td>
                        <td>{lead?.enquiryId?.cashFinanceLease}</td>
                        <td>{lead?.dealerId?.businessName.replace(/_/g, ' ')}</td>
                        <td>{lead?.dealerId?.email}</td>
                        <td>{lead?.dealerId?.phone}</td>
                        {/* NEW: Payment Status */}
                        <td>{lead.isVisible ? 'Paid' : 'Not Paid'}</td>

                        {/* NEW: Payment Method */}
                        <td>
                          {lead.isVisible
                            ? `${lead.paidVia.charAt(0).toUpperCase()}${lead.paidVia.slice(1)}`
                            : '—'}
                        </td>
                        <td>{new Date(lead.sentDate).toLocaleString()}</td>
                        <td>
                          {/* to={`/leads/view/${lead._id}`} */}
                          <Link to={`/viewFinance/${lead?.enquiryId?._id}`} className="me-2">
                            <Button variant="outline-info" size="sm" className="mx-1">
                              <FaEye />
                            </Button>
                          </Link>

                          {/* <Button
                            variant="link"
                            className="p-0"
                            onClick={() => handleDelete(lead._id)}
                          >
                            <FaTrashAlt color="red" />
                          </Button> */}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
            </Col>
          </Row>

          {/* Pagination */}
          {totalPages > 1 && (
            <Row>
              <Col className="d-flex justify-content-center">{renderPagination()}</Col>
            </Row>
          )}
        </Container>
      </div>
    </div>
  )
}

export default LeadSharings
