import React, { useState, useEffect } from 'react';
import { Form, Button, Row, Col, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Constants } from 'src/Constants';
import Swal from 'sweetalert2';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const AboutUsForm = () => {
  const [formData, setFormData] = useState({
    heading: '',
    longDescription: '',
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchAboutUsData();
  }, []);

  const fetchAboutUsData = () => {
    fetch(Constants.getAboutUs)
      .then((response) => response.json())
      .then((data) => {
        setFormData({
          heading: data.heading || '',
          longDescription: data.longDescription || '',
        });
        setLoading(false); 
      })
      .catch((error) => {
        console.error('Error fetching About Us data:', error);
        setLoading(false); 
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const url = Constants.postOrUpdateAboutUs;
    const method = 'POST';

    fetch(url, {
      method: method,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Data updated successfully',
        });
      })
      .catch((error) => {
        console.error('Error updating About Us data:', error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Failed to update About Us data',
        });
      });
  };

  if (loading) {
    return <div className="d-flex justify-content-center align-items-center" style={{ height: '80vh' }}>
    <Spinner animation="border" />
  </div>;
  }

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col>
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0 text-info">About-Us</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <Link to="/dashboard">Home</Link>
                    </li>
                    <li className="breadcrumb-item active text-info">About-Us</li>
                  </ol>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={16}>
              <div className="card custom-shadow rounded-lg border my-2">
                <div className="card-body">
                  <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="formHeading" className="my-2">
                      <h5>Heading</h5>
                      <Form.Control
                        type="text"
                        placeholder="Enter heading"
                        name="heading"
                        value={formData.heading}
                        onChange={(e) => setFormData({ ...formData, heading: e.target.value })}
                      />
                    </Form.Group>

                    <Form.Group controlId="formLongDescription" className="my-2">
                      <h5>Description</h5>
                      <CKEditor
                        editor={ClassicEditor}
                        data={formData.longDescription}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setFormData({ ...formData, longDescription: data });
                        }}
                      />
                    </Form.Group>

                    <Button variant="primary" type="submit" className="my-1">
                      {formData.heading ? 'Update' : 'Create'}
                    </Button>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default AboutUsForm;
